import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import StyleProvider from '../../shared/StyleProvider';
import Logo from '../../assets/logo.png';
import Surprised2 from '../../assets/surprised2.jpg'
import { BsCheckCircle } from 'react-icons/bs';
import { SiYoutubemusic } from 'react-icons/si'
import { IoMdMusicalNote } from "react-icons/io";
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { FaApple, FaSpotify, FaTiktok } from 'react-icons/fa';
import { accordionItems } from '../../configs/globalData';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import PolicyModal from '../../shared/PolicyModal';

const Fade = require('react-reveal/Fade');

export default function Home() {
          const [openIndex, setOpenIndex] = useState<number | null>(null);

          const toggleAccordion = (index: number) => {
                    if (openIndex === index) {
                              setOpenIndex(null);
                    } else {
                              setOpenIndex(index);
                    }
          };

          return (
                    <StyleProvider>
                              <Fade bottom distance="20px">
                                        <div className='pb-20 pt-12 md:pt-20 px-3 md:px-28 md:h-screen'>
                                                  <div className='flex justify-center items-center pb-10 z-0'>
                                                            <Link to={'/apply'} className='glass px-3 py-1 rounded-full text-xs md:text-sm flex items-center gap-2 group'><span className='font-bold'>NEW</span><span className='hover:text-gradientPrimary duration-300 font-semibold flex items-center gap-1'> Apply Now <HiOutlineArrowNarrowRight className='group-hover:ml-1 duration-300' /></span></Link>
                                                  </div>
                                                  <h1 className='text-5xl md:text-6xl text-center font-lemonMilk px-3 md:px-0'>Release Your Music Worldwide</h1>
                                                  <p className='pt-8 md:pt-12 w-full lg:w-4/5 mx-auto text-base md:text-lg text-center font-monospaceTypewriter px-3 md:px-0'>We help you get your music on all major streaming platforms. Distribute your music now and collect royalties in the easiest and fastest way using Mirror-LTD Music.</p>
                                                  <div className='flex justify-center gap-5 pt-12 md:pt-16 duration-300'>
                                                            <Link to={'/apply'} className="text-white py-2 px-5 rounded-full font-bold text-lg md:text-xl bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                      Apply Now
                                                            </Link>
                                                            <a href={'https://music.mirrorltd.com/login'} className="z-0 glass py-2 px-5 rounded-full font-bold text-lg md:text-xl duration-300 hover:text-white hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                      Login
                                                            </a>
                                                  </div>
                                                  <div className='my-20 mt-24 md:mt-28 lg:mt-40 flex justify-center items-center'>
                                                            <div className='mouse relative w-[1.93rem] h-10 border-2 border-gradientPrimary rounded-full'></div>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 mb-16 md:mb-24 glass py-12 md:rounded-3xl'>
                                                  <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>INFINITE Digital Music Streaming Platforms Worldwide</h1>
                                                  <p className='text-black pt-5 md:pt-8 w-full lg:w-4/5 mx-auto text-base md:text-lg text-center font-monospaceTypewriter'>Distribute your music on the most popular digital music streaming platforms around the universe.</p>
                                                  <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 pt-10'>
                                                            <Fade bottom distance="20px">
                                                                      <div className='flex justify-center items-center gap-2 glass px-3 py-6 rounded-xl group duration-300 hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary'>
                                                                                <SiYoutubemusic className='text-3xl md:text-4xl text-black group-hover:text-white' />
                                                                                <p className='text-base md:text-lg font-bold group-hover:text-white'>YT Music</p>
                                                                      </div>
                                                            </Fade>
                                                            <Fade bottom distance="20px">
                                                                      <div className='flex justify-center items-center gap-2 glass px-3 py-6 rounded-xl group duration-300 hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary'>
                                                                                <FaApple className='text-3xl md:text-4xl text-black group-hover:text-white' />
                                                                                <p className='text-base md:text-lg font-bold group-hover:text-white'>Apple Music</p>
                                                                      </div>
                                                            </Fade>
                                                            <Fade bottom distance="20px">
                                                                      <div className='flex justify-center items-center gap-2 glass px-3 py-6 rounded-xl group duration-300 hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary'>
                                                                                <FaSpotify className='text-3xl md:text-4xl text-black group-hover:text-white' />
                                                                                <p className='text-base md:text-lg font-bold group-hover:text-white'>Spotify</p>
                                                                      </div>
                                                            </Fade>
                                                            <Fade bottom distance="20px">
                                                                      <div className='flex justify-center items-center gap-2 glass px-3 py-6 rounded-xl group duration-300 hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary'>
                                                                                <FaTiktok className='text-3xl md:text-4xl text-black group-hover:text-white' />
                                                                                <p className='text-base md:text-lg font-bold group-hover:text-white'>TikTok</p>
                                                                      </div>
                                                            </Fade>
                                                            <Fade bottom distance="20px">
                                                                      <div className='flex justify-center items-center gap-2 glass px-3 py-6 rounded-xl group duration-300 hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary'>
                                                                                <IoMdMusicalNote className='text-3xl md:text-4xl text-black group-hover:text-white' />
                                                                                <p className='text-base md:text-lg font-bold group-hover:text-white'>+ More</p>
                                                                      </div>
                                                            </Fade>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12'>
                                                  <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>Even more features</h1>
                                                  <p className='md:w-3/4 lg:w-2/3 mx-auto text-base md:text-lg text-center font-monospaceTypewriter text-black'>We are constantly building new product,s and features to improve your digital music business</p>
                                                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10 mt-10">
                                                            <Fade bottom distance="20px">
                                                                      <div className='text-black border-t border-r border-gradientSecondary glass p-5 rounded-3xl'>
                                                                                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold mb-3'>Fast Distribution</h1>
                                                                                <p className='text-base md:text-lg font-semibold'>Our platform utilizes AI and automation technology to deliver your music</p>
                                                                      </div>
                                                            </Fade>
                                                            <Fade bottom distance="20px">
                                                                      <div className='text-black border-t border-r border-gradientSecondary glass p-5 rounded-3xl'>
                                                                                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold mb-3'>Monetization</h1>
                                                                                <p className='text-base md:text-lg font-semibold'>(UGC) on services like Facebook, Instagram, TikTok, Soundcloud, and more.</p>
                                                                      </div>
                                                            </Fade>
                                                            <Fade bottom distance="20px">
                                                                      <div className='text-black border-t border-r border-gradientSecondary glass p-5 rounded-3xl'>
                                                                                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold mb-3'>Youtube Content ID</h1>
                                                                                <p className='text-base md:text-lg font-semibold'>Register your music with YouTube Content ID to automatically collect money</p>
                                                                      </div>
                                                            </Fade>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 my-16 md:my-24'>
                                                  <div className='glass p-5 rounded-3xl'>
                                                            <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>How can I publish my music?</h1>
                                                            <p className='md:w-3/4 lg:w-2/3 mx-auto text-base md:text-lg text-center font-monospaceTypewriter text-black'>With Mirror-LTD, every independent artist & lables can distribute their tracks worldwide.</p>
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mx-auto gap-5 md:gap-10 mt-10 md:px-5 lg:px-10 pb-5'>
                                                                      <Fade bottom distance="20px">
                                                                                <div className='border-t border-r border-gradientSecondary p-5 rounded-3xl glass flex flex-col gap-2'>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Apply On Mirror-LTD Music</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>The team will review</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>You’ll be notified</p>
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                                      <Fade bottom distance="20px">
                                                                                <div className='border-t border-r border-gradientSecondary p-5 rounded-3xl glass flex flex-col gap-2'>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>If approved, We’ll get you in</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Log into the Mirror-LTD Music portal.</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Publish your music</p>
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                            </div>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 my-16 md:my-24'>
                                                  <div className='glass p-5 rounded-3xl'>
                                                            <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>Distribute your music globally</h1>
                                                            <p className='md:w-3/4 lg:w-2/3 mx-auto text-base md:text-lg text-center font-monospaceTypewriter text-black'>Release your music to online 250+ platforms and go viral. Get yourself on major platforms</p>
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mx-auto gap-5 md:gap-10 mt-10 md:px-5 lg:px-10 pb-5'>
                                                                      <Fade bottom distance="20px">
                                                                                <div className='border-t border-r border-gradientSecondary p-5 rounded-3xl glass flex flex-col gap-2'>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Unlimited Global Distribution</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>250+ Music Streaming Platforms</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Own Your Music</p>
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                                      <Fade bottom distance="20px">
                                                                                <div className='border-t border-r border-gradientSecondary p-5 rounded-3xl glass flex flex-col gap-2'>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Unlimited artist for label</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Unlimited Releases</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Content creation</p>
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                            </div>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 my-16 md:my-24'>
                                                  <div className='glass p-5 rounded-3xl'>
                                                            <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>Release your music online with Mirror-LTD Music?</h1>
                                                            <p className='md:w-3/4 lg:w-2/3 mx-auto text-base md:text-lg text-center font-monospaceTypewriter text-black'>Our Dashboard meet all your music distribution needs. Follow these easy steps.</p>
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mx-auto gap-5 md:gap-10 mt-10 md:px-5 lg:px-10 pb-5'>
                                                                      <Fade bottom distance="20px">
                                                                                <div className='border-t border-r border-gradientSecondary p-5 rounded-3xl glass flex flex-col gap-2'>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Find your login credentials on mail</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Create your A&R Name</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Upload music after</p>
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                                      <Fade bottom distance="20px">
                                                                                <div className='border-t border-r border-gradientSecondary p-5 rounded-3xl glass flex flex-col gap-2'>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Start distributing your music</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Your music will be distributed</p>
                                                                                          </div>
                                                                                          <div className='flex items-center gap-2 text-black'>
                                                                                                    <BsCheckCircle />
                                                                                                    <p className='text-base md:text-lg font-semibold'>Generate revenue as well</p>
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                            </div>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 md:hidden glass py-12'>
                                                  <div className="hero">
                                                            <div className="hero-content flex-col justify-between">
                                                                      <Fade bottom distance="20px">
                                                                                <div className="lg:hidden w-full lg:w-1/2 rounded-full overflow-hidden lg:ml-6">
                                                                                          <div className="outline-none">
                                                                                                    <img
                                                                                                              src={Surprised2}
                                                                                                              className="w-full"
                                                                                                              alt="surprised2"
                                                                                                    />
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                                      <Fade bottom distance="30px">
                                                                                <div className="lg:w-1/2 pt-11 lg:pt-0 leading-loose">
                                                                                          <h1 className="text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black">Distribute Your Music @ <span className="text-2xl md:text-3xl lg:text-4xl font-bold leading-tight text-red-500 font-lemonMilk">No Cost</span></h1>
                                                                                          <p className="py-6 text-base md:text-lg font-monospaceTypewriter">We charge nothing upfront. Every service is provided free of charges and we charge a minimal commission based on your revenue.</p>
                                                                                </div>
                                                                      </Fade>
                                                            </div>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 my-16 md:my-24 glass py-12 md:rounded-3xl hidden md:flex'>
                                                  <div className='flex flex-col'>
                                                            <Fade bottom distance="20px">
                                                                      <div className="w-full rounded-full overflow-hidden pb-12">
                                                                                <div className="outline-none">
                                                                                          <img
                                                                                                    src={Surprised2}
                                                                                                    className="md:w-1/2 lg:w-1/4 mx-auto rounded-full"
                                                                                                    alt="surprised2"
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </Fade>
                                                            <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>Distribute Your Music @ <span className="text-2xl md:text-3xl lg:text-4xl font-bold leading-tight text-red-500 font-lemonMilk">No Cost</span></h1>
                                                            <p className='text-black pt-5 md:pt-8 w-full lg:w-4/5 mx-auto text-base md:text-lg text-center font-monospaceTypewriter'>We charge nothing upfront. Every service is provided free of charges and we charge a minimal commission based on your revenue.</p>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 my-16 md:my-24'>
                                                  <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>Frequently Asked Questions</h1>
                                                  <div className='grid grid-cols-1 gap-5 pt-10'>
                                                            {
                                                                      accordionItems.map((item, index) => (
                                                                                <div className='glass px-6 py-6 md:py-10 rounded-3xl duration-300' key={index}>
                                                                                          <div className='flex justify-between items-center gap-2 cursor-pointer select-none' onClick={() => toggleAccordion(index)}>
                                                                                                    <h1 className='text-base font-lemonMilk'>{item.title}</h1>
                                                                                                    <div>
                                                                                                              {openIndex === index ? <AiOutlineMinus className='font-bold text-xl inline-block' /> : <AiOutlinePlus className='font-bold text-xl inline-block' />}
                                                                                                    </div>
                                                                                          </div>
                                                                                          {openIndex === index && (
                                                                                                    <div className="mt-4 duration-300">
                                                                                                              <p className="text-gray-600 text-sm md:text-base pr-6 font-monospaceTypewriter">{item.content}</p>
                                                                                                    </div>
                                                                                          )}
                                                                                </div>
                                                                      ))
                                                            }
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-12 my-16 md:my-24 glass py-12 md:rounded-3xl'>
                                                  <div className='flex flex-col'>
                                                            <h1 className='text-black text-2xl md:text-6xl text-center font-lemonMilk'>Distribute your music worldwide</h1>
                                                            <p className='text-black pt-5 md:pt-8 w-full lg:w-4/5 mx-auto text-base md:text-lg text-center font-monospaceTypewriter'>Worldwide music distribution for independence artists, is made easy</p>
                                                            <div className='flex justify-center gap-5 pt-10'>
                                                                      <Link to={'/apply'} className="text-white py-2 px-5 rounded-full font-bold text-lg md:text-xl bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                                Apply Now
                                                                      </Link>
                                                                      <a href={'https://music.mirrorltd.com/login'} className="text-black glass py-2 px-5 rounded-full font-bold text-lg md:text-xl duration-300 hover:text-white hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                                Login
                                                                      </a>
                                                            </div>
                                                  </div>
                                        </div>
                              </Fade>

                              <Fade bottom distance="20px">
                                        <div className='pb-10 px-3 md:px-0'>
                                                  <Fade bottom distance="20px">
                                                            <div className='flex flex-col md:flex-row justify-between items-center gap-10 md:gap-10 w-full pt-24 text-black'>
                                                                      <div className='w-full md:w-1/2 glass px-4 md:px-12 py-4 rounded-3xl'>
                                                                                <img src={Logo} alt="logo" className='h-20 w-20 mb-3' />
                                                                                <p className='font-monospaceTypewriter'>We Support independent artists & labels and help them to Spread their Music to be heard around the World. We provide a dedicated team to help you</p>
                                                                                <ul className='px-4 py-5 list-disc'>
                                                                                          <li className='font-monospaceTypewriter duration-300 hover:underline hover:text-gradientPrimary'><a href="mailto:support@mirrorltd.com">support@mirrorltd.com</a></li>
                                                                                </ul>
                                                                      </div>
                                                                      <div className='flex flex-col items-start pl-8 glass py-5 gap-2 rounded-3xl w-full md:w-1/3 lg:w-1/3'>
                                                                                <h1 className='mb-3 text-lg font-bold font-lemonMilk'>Our Policy</h1>
                                                                                <PolicyModal title='Terms of use' description='The following are the General Terms & Conditions of your account. By opening your own account, you agree to these terms & conditions as well as the associated privacy policy in its entirety.' url='Terms of Use' />
                                                                                <PolicyModal title='Anti-Fraud Policy' description='You guarantee that your Mirror-LTD Music account will not be used for fraudulent activities or purposes other than Mirror-LTD Music original intention.' url='Anti-Fraud Policy' />
                                                                                <PolicyModal title='Warranty' description='You warrant that your email address is up-to-date. You warranty that you are responsible for the safekeeping of your login details.' url='Warranty' />
                                                                                <PolicyModal title='Guarantee' description='You guarantee that you are older than 18 years of age.' url='Guarantee' />
                                                                      </div>
                                                            </div>
                                                  </Fade>

                                                  <footer className='flex flex-col justify-center items-center h-12 text-black mt-20'>
                                                            <p className='text-xs md:text-sm text-center font-monospaceTypewriter'>Copyright &copy; {new Date().getFullYear()} <span className='hover:text-gradientPrimary font-bold duration-300 cursor-pointer'>Mirror-LTD Music</span> - All Rights Reserved.</p>
                                                  </footer>
                                        </div>
                              </Fade>
                    </StyleProvider>
          );
};