import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { MEASUREMENT_ID } from '../config';

export default function Analytics() {
          useEffect(() => {
                    ReactGA.initialize(MEASUREMENT_ID);
                    ReactGA.send({
                              hitType: "pageview",
                              page: window.location.pathname + window.location.search,
                              title: document.title
                    });
          }, [])

          return null
}