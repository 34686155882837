import React from 'react'
import Navbar from '../pages/Home/Navbar'

export default function StyleProvider({ children }: { children: React.ReactNode }) {
          return (
                    <div className='bg-[url("./assets/bg-light.jpg")] bg-contain'>
                              <div className='w-full md:w-[85%] lg:w-[65%] mx-auto'>
                                        <Navbar />
                                        {children}
                              </div>
                    </div>
          )
}
