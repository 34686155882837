import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import Logo from '../../assets/logo.png'
import { AiOutlineClose } from 'react-icons/ai';

export default function Navbar() {
          const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

          const toggleMobileMenu = () => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
          };

          const MenuLinks =
                    <>
                              <NavLink
                                        to="/faq"
                                        className={({ isActive }) =>
                                                  isActive ? "text-white transition duration-300 font-bold py-1 px-2 rounded-full bg-gradient-to-l from-gradientSecondary to-gradientPrimary" : `hover:text-white transition duration-300 font-bold py-1 px-2 rounded-full hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary`
                                        }
                              >
                                        <span className='flex md:hidden lg:flex font-monospaceTypewriter'>Music Distribution FAQ</span>
                                        <span className='hidden md:flex lg:hidden font-monospaceTypewriter'>FAQ</span>
                              </NavLink>
                              <NavLink
                                        to="/about-us"
                                        className={({ isActive }) =>
                                                  isActive ? "text-white font-monospaceTypewriter transition duration-300 font-bold py-1 px-2 rounded-full bg-gradient-to-l from-gradientSecondary to-gradientPrimary" : `hover:text-white font-monospaceTypewriter transition duration-300 font-bold py-1 px-2 rounded-full hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary`
                                        }
                              >
                                        About Us
                              </NavLink>
                              <NavLink
                                        to="/support"
                                        className={({ isActive }) =>
                                                  isActive ? "text-white font-monospaceTypewriter transition duration-300 font-bold py-1 px-2 rounded-full bg-gradient-to-l from-gradientSecondary to-gradientPrimary" : `hover:text-white font-monospaceTypewriter transition duration-300 font-bold py-1 px-2 rounded-full hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary`
                                        }
                              >
                                        Support
                              </NavLink>
                              <NavLink
                                        to="/pricing"
                                        className={({ isActive }) =>
                                                  isActive ? "text-white font-monospaceTypewriter transition duration-300 font-bold py-1 px-2 rounded-full bg-gradient-to-l from-gradientSecondary to-gradientPrimary" : `hover:text-white font-monospaceTypewriter transition duration-300 font-bold py-1 px-2 rounded-full hover:bg-gradient-to-l from-gradientSecondary to-gradientPrimary`
                                        }
                              >
                                        Pricing
                              </NavLink>
                    </>

          return (
                    <nav>
                              <div className="container mx-auto px-4">
                                        <div className="flex justify-between items-center py-3">
                                                  <Link to="/" className="text-2xl font-bold">
                                                            <img src={Logo} alt="logo" className='h-12 md:h-16 lg:h-20 w-12 md:w-16 lg:w-20' />

                                                  </Link>
                                                  <div className="hidden duration-300 md:flex space-x-6">
                                                            {MenuLinks}
                                                  </div>
                                                  <div className='hidden md:flex'>
                                                            <a href={'https://music.mirrorltd.com/login'} className="text-white py-1 px-2 rounded-full font-bold text-sm md:text-base bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                      Client Login
                                                            </a>
                                                  </div>
                                                  <div className="md:hidden flex items-center gap-5">
                                                            <a href={'https://music.mirrorltd.com/login'} className="text-white py-1 px-2 rounded-full font-bold text-sm md:text-base bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                      Client Login
                                                            </a>
                                                            <button onClick={toggleMobileMenu} className='z-30'>
                                                                      {isMobileMenuOpen ?
                                                                                (<AiOutlineClose size={30} />)
                                                                                :
                                                                                (<HiOutlineMenuAlt4 size={30} />)
                                                                      }
                                                            </button>
                                                  </div>
                                        </div>
                              </div>
                              {isMobileMenuOpen && (
                                        <div className="md:hidden absolute top-0 w-full z-10">
                                                  <div className="bg-[url('./assets/bg-light.jpg')] bg-cover flex flex-col justify-start items-center gap-4 px-2 pt-24 h-screen">
                                                            {MenuLinks}
                                                  </div>
                                        </div>
                              )}
                    </nav>
          )
}
