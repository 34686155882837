import React, { useState } from 'react'
import StyleProvider from '../../shared/StyleProvider'
import { accordionItems } from '../../configs/globalData'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import useTitle from '../../hooks/useTitle';

export default function Faq() {
          useTitle('Frequently Asked Questions');
          const [openIndex, setOpenIndex] = useState<number | null>(null);

          const toggleAccordion = (index: number) => {
                    if (openIndex === index) {
                              setOpenIndex(null);
                    } else {
                              setOpenIndex(index);
                    }
          };

          return (
                    <StyleProvider>
                              <div className='pb-10 px-3 md:px-12 py-8'>
                                        <h1 className='text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black'>Frequently Asked Questions</h1>
                                        <div className='grid grid-cols-1 gap-5 pt-10'>
                                                  {
                                                            accordionItems.map((item, index) => (
                                                                      <div className='glass px-6 py-6 md:py-10 rounded-3xl duration-300' key={index}>
                                                                                <div className='flex justify-between items-center gap-2 cursor-pointer select-none' onClick={() => toggleAccordion(index)}>
                                                                                          <h1 className='text-base md:text-lg font-bold'>{item.title}</h1>
                                                                                          <div>
                                                                                                    {openIndex === index ? <AiOutlineMinus className='font-bold text-xl inline-block' /> : <AiOutlinePlus className='font-bold text-xl inline-block' />}
                                                                                          </div>
                                                                                </div>
                                                                                {openIndex === index && (
                                                                                          <div className="mt-4 duration-300">
                                                                                                    <p className="text-gray-600 text-sm md:text-base font-semibold pr-6">{item.content}</p>
                                                                                          </div>
                                                                                )}
                                                                      </div>
                                                            ))
                                                  }
                                        </div>
                              </div>
                    </StyleProvider>
          )
}
