export const data = [];

export const accordionItems = [
          {
                    title: 'How to upload music on Spotify & other streaming stores',
                    content: 'If you want to upload music on Spotify and other streaming stores you need a Music Distributor like Mirror-LTD Music. Stores don’t allow users to upload their music directly because they don’t want to deal with copyright problems or guideline problems and because of this, they select partners like Mirror-LTD Music to Distribute Music and take care of payments, guidelines, copyright, and everything related to this. To publish your music you just need to create an account on Mirror-LTD Music and then you can start uploading your music on our distribution platform.',
          },
          {
                    title: 'How do I monetize my songs on YouTube music?',
                    content: 'Mirror-LTD Music Distribution offers YouTube Content ID for our Users. YouTube Content ID helps you to get all streaming revenue generated by your music. For example; if someone on YouTube uses your Music in his video, we will claim it automatically and you will get all earnings generated by that video. Your Music, your money.',
          },
          {
                    title: 'How can I upload music on TikTok, Instagram, and Facebook?',
                    content: 'We on Mirror-LTD Music got you covered. If you submit music with us your music will be automatically Distributed to all streaming stores around the world, including TikTok Music, Instagram Music, and Facebook Music. There is no additional step you need to go. We publish your music to all Streaming Stores and Social Media Music Stores.',
          },
          {
                    title: 'What is Independent music distribution?',
                    content: 'Back in the day, musicians like you could only publish music with the help of Label Management and it’s often hard to become a label member. With Mirror-LTD Music it’s easy; you can be your own music label and they do not charge anything upfront. Each service is provided for free and they charge a minimum commission based on your revenue.',
          },
          {
                    title: 'Free Music Distribution, how?',
                    content: 'Do you wonder how we can offer Free Music Distribution to everyone? Here is the answer; we want to give every artist out there the chance to get heard around the world, and if you have just started your music career you maybe just want to get your music on Spotify, TikTok, Deezer, Apple Music, etc. without any types of costs, that’s why we implemented the free music distribution.',
          },
          {
                    title: 'Can I transfer my Music from DistroKid, TuneCore, Believe Music, RouteNote, or any other Music Distribution Service?',
                    content: 'Yes, you can! And the best part is; Your Music stays live at stores without any type of downtime. You won’t lose any statistics (Streams, Playlist Positions, etc) The transfer is absolutely seamless. Just make sure to use the same metadata and ISRC while submitting you’ve already published music (Cover Art, Artist Name, Song Name, Audio File, etc.) (ISRC: You can always find that Code on your actual distributor like DistroKid, TuneCore, etc.) If you got any questions regarding the transfer our team is always happy to help you, just open a ticket or send us an email: support@mirrorltd.com',
          },
];
