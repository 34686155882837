import React from 'react'
import { Outlet } from 'react-router-dom';
import Home from '../pages/Home/Home';
import ScrollToTop from '../shared/ScrollToTop';

export default function Root() {
  document.title = 'Mirror-LTD Music | Music Distribution';
  return (
    <>
      <Home />
      <ScrollToTop />
      <Outlet />
    </>
  )
}
