import { createBrowserRouter } from "react-router-dom";
import Root from "../Layouts/Root";
import Apply from "../pages/Apply/Apply";
import AboutUs from "../pages/Home/AboutUs";
import Faq from "../pages/Home/Faq";
import Pricing from "../pages/Home/Pricing";
import Support from "../pages/Home/Support";
import NotFound from "../shared/NotFound";

const router = createBrowserRouter(
          [
                    {
                              path: '/',
                              element: <Root />
                    },
                    {
                              path: '/about-us',
                              element: <AboutUs />
                    },
                    {
                              path: '/faq',
                              element: <Faq />
                    },
                    {
                              path: '/support',
                              element: <Support />
                    },
                    {
                              path: '/pricing',
                              element: <Pricing />
                    },
                    {
                              path: '/apply',
                              element: <Apply />
                    },
                    {
                              path: '*',
                              element: <NotFound />
                    }
          ]
);

export default router;