import React from 'react'
import Logo from '../assets/logo.png';
import { BeatLoader } from 'react-spinners'

export default function Loading() {
          return (
                    <div className="flex flex-col justify-center items-center h-screen w-screen bg-[url('./assets/bg-light.jpg')] bg-contain">
                              <img src={Logo} alt="logo" className="w-20" />
                              <div className="flex justify-center items-center mt-2">
                                        <BeatLoader color="#000" size={10} />
                              </div>
                    </div>
          )
}
