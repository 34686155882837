import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { BASE_API } from '../../config';
import useTitle from '../../hooks/useTitle';
import { AiOutlineUser, AiOutlinePhone } from 'react-icons/ai'
import { IoMdMusicalNote } from 'react-icons/io'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { IoDocumentTextOutline } from 'react-icons/io5'
import useScrollToTop from '../../hooks/useScrollToTop';
import ReCAPTCHA from "react-google-recaptcha";

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

export default function Apply() {
          useTitle("Apply");
          useScrollToTop();

          const [reCaptchaValue, setReCaptchaValue] = useState<string | null>(null);
          const reCaptchaRef = useRef<ReCAPTCHA>(null);

          const handleSubmit = (e: any) => {
                    e.preventDefault();

                    const form = e.target as typeof e.target & {
                              firstName: { value: string };
                              lastName: { value: string };
                              artistName: { value: string };
                              email: { value: string };
                              phoneNumber: { value: string };
                              artistBio: { value: string };
                              terms: { value: string };
                    };

                    const firstName = form.firstName.value;
                    const lastName = form.lastName.value;
                    const artistName = form.artistName.value;
                    const email = form.email.value;
                    const phoneNumber = form.phoneNumber.value;
                    const artistBio = form.artistBio.value;
                    const terms = form.terms.checked;

                    const data = {
                              email,
                              firstName,
                              lastName,
                              phoneNumber,
                              artistName,
                              artistBio,
                              reCaptchaValue,
                    };

                    if (!firstName) {
                              toast.error('Please enter your first name');
                              return;
                    } else if (!lastName) {
                              toast.error('Please enter your last name');
                              return;
                    } else if (!artistName) {
                              toast.error('Please enter your artist name');
                              return;
                    } else if (!email) {
                              toast.error('Please enter your email');
                              return;
                    } else if (!phoneNumber) {
                              toast.error('Please enter your phone number');
                              return;
                    } else if (!form.artistBio.value) {
                              toast.error('Please tell us about yourself')
                    } else if (!reCaptchaValue) {
                              toast.error('Please verify reCaptcha');
                              return;
                    } else {
                              if (!terms) {
                                        toast.error('Please agree to our terms of service');
                                        return;
                              } else {
                                        axios.post(`${BASE_API}/waiting-users/signup`, data)
                                                  .then((res) => {
                                                            if (res.status === 201) {
                                                                      Swal.fire({
                                                                                title: "Success!",
                                                                                text: "Your application has been submitted",
                                                                                icon: "success",
                                                                                showConfirmButton: false,
                                                                                timer: 3000,
                                                                      });
                                                            } else {
                                                                      Swal.fire({
                                                                                title: "Error!",
                                                                                text: res.data.message,
                                                                                icon: "error",
                                                                                showConfirmButton: false,
                                                                                timer: 3000,
                                                                      });
                                                            }
                                                            form.reset();
                                                  })
                              }
                    }

                    reCaptchaRef.current?.reset();
          }

          return (
                    <div className="min-h-screen bg-[url('./assets/bg-light.jpg')] bg-contain md:bg-cover flex items-center justify-center md:px-5 md:py-5">
                              <div className="glass md:rounded-3xl w-full overflow-hidden" style={{ maxWidth: '700px' }}>
                                        <div className="w-full py-5 md:py-5 px-5 md:px-10">
                                                  <div className="text-center mb-5 md:mb-10">
                                                            <h1 className="font-lemonMilk text-3xl text-black">SIGN UP</h1>
                                                            <p className='mt-2 text-black font-monospaceTypewriter'>Enter your details below to apply for <Link to={"/"} className='text-gradientPrimary duration-300'>Mirror-LTD Music</Link> Dashboard</p>
                                                  </div>
                                                  <form onSubmit={handleSubmit}>
                                                            <div className="flex flex-col md:flex-row -mx-3">
                                                                      <div className="w-full px-3 mb-3 md:mb-5">
                                                                                <label htmlFor="" className="text-xs font-semibold px-1 text-black">First name <span className='text-red-500'>*</span></label>
                                                                                <div className="flex">
                                                                                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><AiOutlineUser /></div>
                                                                                          <input type="text" name='firstName' className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered text-black" placeholder="First Name" />
                                                                                </div>
                                                                      </div>
                                                                      <div className="w-full px-3 mb-3 md:mb-5">
                                                                                <label htmlFor="" className="text-xs font-semibold px-1 text-black">Last name <span className='text-red-500'>*</span></label>
                                                                                <div className="flex">
                                                                                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><AiOutlineUser /></div>
                                                                                          <input type="text" name='lastName' className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered text-black" placeholder="Last Name" />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="flex -mx-3">
                                                                      <div className="w-full px-3 mb-3 md:mb-5">
                                                                                <label htmlFor="" className="text-xs font-semibold px-1 text-black">Artist/Band Name <span className='text-red-500'>*</span></label>
                                                                                <div className="flex">
                                                                                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><IoMdMusicalNote /></div>
                                                                                          <input type="text" name='artistName' className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered text-black" placeholder="Your artist/band name" />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="flex -mx-3">
                                                                      <div className="w-full px-3 mb-3 md:mb-5">
                                                                                <label htmlFor="" className="text-xs font-semibold px-1 text-black">Email <span className='text-red-500'>*</span></label>
                                                                                <div className="flex">
                                                                                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><MdOutlineAlternateEmail /></div>
                                                                                          <input type="email" name='email' className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered text-black" placeholder="Your email" />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="flex -mx-3">
                                                                      <div className="w-full px-3 mb-3 md:mb-5">
                                                                                <label htmlFor="" className="text-xs font-semibold px-1 text-black">Phone Number <span className='text-red-500'>*</span></label>
                                                                                <div className="flex">
                                                                                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><AiOutlinePhone /></div>
                                                                                          <input type="number" name='phoneNumber' className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered text-black" placeholder="Phone number" />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="flex -mx-3">
                                                                      <div className="w-full px-3 mb-3 md:mb-5">
                                                                                <label htmlFor="" className="text-xs font-semibold px-1 text-black">Tell us about yourself <span className='text-red-500'>*</span></label>
                                                                                <div className="flex">
                                                                                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><IoDocumentTextOutline /></div>
                                                                                          <textarea typeof="text" style={{ resize: 'none' }} name='artistBio' className="h-[200px] md:h-[150px] py-3 bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered text-black" placeholder="About yourself" />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className='flex items-start gap-2 mb-5'>
                                                                      <input type="checkbox" name='terms' className="checkbox checkbox-xs" />
                                                                      <p className='text-xs text-black'>I have read and agree to Mirror LTD's <a href="/" className='text-gradientPrimary'>Terms of services <span className='text-red-500'>*</span></a></p>
                                                            </div>
                                                            <div className="px-1 mb-5 flex justify-center">
                                                                      <ReCAPTCHA
                                                                                sitekey={siteKey}
                                                                                onChange={value => setReCaptchaValue(value)}
                                                                                ref={reCaptchaRef}
                                                                      />
                                                            </div>
                                                            <div className="flex -mx-3 mb-5">
                                                                      <div className="w-full px-3">
                                                                                <button className="block btn btn-sm md:btn-md md:px-6 bg-gradient-to-l from-gradientSecondary to-gradientPrimary border-none mx-auto text-white font-semibold">Submit</button>
                                                                      </div>
                                                            </div>
                                                            <div className='text-center text-sm'>
                                                                      <p className='text-black'>Already have an account? <a href="https://music.mirrorltd.com/login" className='text-gradientPrimary'>Login</a></p>
                                                            </div>
                                                  </form>
                                        </div>
                              </div>
                    </div>
          )
}
