import React from 'react'
import StyleProvider from '../../shared/StyleProvider'
import useTitle from '../../hooks/useTitle'

export default function AboutUs() {
          useTitle('About Us');
          return (
                    <StyleProvider>
                              <div className='pb-10 px-3 md:px-12 py-8 h-screen'>
                                        <h1 className='text-2xl md:text-3xl lg:text-4xl pb-4 md:pt-4 text-center font-lemonMilk text-black'>About Us</h1>
                                        <h1 className='text-lg md:text-xl lg:text-2xl px-2 md:px-0 pt-8 w-full lg:w-4/5 mx-auto text-start font-lemonMilk text-black'>Who we are</h1>
                                        <p className='text-black pt-5 px-2 md:px-0 w-full lg:w-4/5 mx-auto text-base md:text-lg text-start font-monospaceTypewriter'>We believe music shouldn’t just be a hobby; musicians deserve to be paid. From day one, we’ve fought to tear down the walls erected by the music industry. And we won. Each day, we strive to give independent artists and labels access to the marketplace so they can compete on equal footing and build the lives they deserve. </p>
                                        <p className='text-black pt-5 md:pt-8 px-2 md:px-0 w-full lg:w-4/5 mx-auto text-base md:text-lg text-start font-monospaceTypewriter'>Our mission is to be heroes for musicians and independent record labels around the world. We want to make it easy for everyone to sell, manage, protect, and monetize their music. If you’re financially sustainable, you’ll make more music. It’s that simple.</p>
                              </div>
                    </StyleProvider>
          )
}
