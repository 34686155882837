import React from 'react'
import StyleProvider from '../../shared/StyleProvider'
import Surprised from '../../assets/surprised.jpg'
import Surprised2 from '../../assets/surprised2.jpg'
import useTitle from '../../hooks/useTitle';

const Fade = require('react-reveal/Fade');

export default function Pricing() {
          useTitle('Pricing');
          return (
                    <StyleProvider>
                              <div className='pb-10 px-3 md:px-12 lg:pt-12 md:h-screen'>
                                        <section className="body-font">
                                                  <div className="hero">
                                                            <div className="hero-content flex-col justify-between lg:flex-row-reverse">
                                                                      <Fade bottom distance="20px">
                                                                                <div className="lg:hidden w-full lg:w-1/2 rounded-full overflow-hidden lg:ml-6">
                                                                                          <div className="outline-none">
                                                                                                    <img
                                                                                                              src={Surprised2}
                                                                                                              className="w-full md:w-3/5 mx-auto rounded-full"
                                                                                                              alt="surprised2"
                                                                                                    />
                                                                                          </div>
                                                                                </div>
                                                                                <div className="hidden lg:flex w-full lg:w-1/2 rounded-full overflow-hidden lg:ml-6">
                                                                                          <div className="outline-none">
                                                                                                    <img
                                                                                                              src={Surprised}
                                                                                                              className="w-full lg:w-4/5 mx-auto rounded-full"
                                                                                                              alt="surprised"
                                                                                                    />
                                                                                          </div>
                                                                                </div>
                                                                      </Fade>
                                                                      <Fade bottom distance="30px">
                                                                                <div className="lg:w-1/2 pt-11 lg:pt-0 leading-loose">
                                                                                          <h1 className="text-3xl md:text-5xl font-bold leading-tight font-lemonMilk">Distribute Your Music @ <span className="text-3xl md:text-5xl font-bold leading-tight text-red-500 font-lemonMilk">No Cost</span></h1>
                                                                                          <p className="py-6 font-monospaceTypewriter">We charge nothing upfront. Every service is provided free of charges and we charge a minimal commission based on your revenue.</p>
                                                                                </div>
                                                                      </Fade>
                                                            </div>
                                                  </div>
                                        </section>
                              </div>
                    </StyleProvider>
          )
}
