import React, { useState } from "react";
import { HiOutlineArrowNarrowUp } from 'react-icons/hi'

export default function ScrollToTop() {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400) {
      setVisible(true);
    } else if (scrolled <= 400) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className={`fixed cursor-pointer text-[2.5rem] w-full z-10 left-[86%] md:left-[93%] lg:left-[96%] bottom-[50px] md:bottom-[60px] lg:bottom-[70px] h-[20px]`}>
      <HiOutlineArrowNarrowUp
        onClick={scrollToTop}
        className="text-3xl md:text-4xl bg-gradient-to-br from-gradientSecondary to-gradientPrimary text-white p-2 rounded-lg"
        style={{ display: visible ? "inline" : "none" }}
      />
    </div>
  );
};
