import React from 'react'

type Props = {
          title: string;
          description: string;
          url: string;
}

export default function PolicyModal({ title, description, url }: Props) {
          return (
                    <div className="dropdown md:dropdown-end">
                              <label tabIndex={0} className="cursor-pointer duration-300 hover:text-gradientPrimary hover:underline text-sm font-lemonMilk">
                                        {url}
                              </label>
                              <div tabIndex={0} className="card compact dropdown-content z-[1] bg-[url('./assets/bg-light.jpg')] bg-cover border rounded-box w-64">
                                        <div className="card-body">
                                                  <h2 className="card-title font-lemonMilk">{title}</h2>
                                                  <p className='font-monospaceTypewriter'>{description}</p>
                                        </div>
                              </div>
                    </div>
          )
}
